/*Font-family*/
@font-face {
    font-family: 'TT Commons';
    src: url('fonts/tt-commons/TTCommons-Regular.eot');
    src: local('TT Commons Regular'), local('TTCommons-Regular'),
        url('fonts/tt-commons/TTCommons-Regular-.eot#iefix') format('embedded-opentype'),
        url('fonts/tt-commons/TTCommons-Regular.woff2') format('woff2'),
        url('fonts/tt-commons/TTCommons-Regular.woff') format('woff'),
        url('fonts/tt-commons/TTCommons-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'TT Commons';
    src: url('fonts/tt-commons/TTCommons-Medium.eot'),
        url('fonts/tt-commons/TTCommons-Medium-.eot#iefix') format('embedded-opentype'),
        url('fonts/tt-commons/TTCommons-Medium.woff2') format('woff2'),
        url('fonts/tt-commons/TTCommons-Medium.woff') format('woff'),
        url('fonts/tt-commons/TTCommons-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('fonts/roboto/hinted-RobotoMono-Medium.ttf') format('truetype'),
        url('fonts/roboto/hinted-RobotoMono-Medium.eot') format('eot'),
        url('fonts/roboto/hinted-RobotoMono-Medium.woff') format('woff'),
        url('fonts/roboto/hinted-RobotoMono-Medium.woff2') format('woff2'),
        url('fonts/roboto/hinted-RobotoMono-Medium.svg#RobotoMono-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('fonts/roboto/hinted-RobotoMono-Regular.ttf') format('truetype'),
        url('fonts/roboto/hinted-RobotoMono-Regular.eot') format('eot'),
        url('fonts/roboto/hinted-RobotoMono-Regular.woff') format('woff'),
        url('fonts/roboto/hinted-RobotoMono-Regular.woff2') format('woff2'),
        url('fonts/roboto/hinted-RobotoMono-Regular.svg#RobotoMono-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url('fonts/calibre/hinted-Calibre-Medium.ttf') format('truetype'),
        url('fonts/calibre/hinted-Calibre-Medium.woff') format('woff'),
        url('fonts/calibre/hinted-Calibre-Medium.woff2') format('woff2'),
        url('fonts/calibre/hinted-Calibre-Medium.eot') format('eot'),
        url('fonts/calibre/hinted-Calibre-Medium.svg#Calibre-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url('fonts/calibre/hinted-Calibre-Regular.ttf') format('truetype'),
        url('fonts/calibre/hinted-Calibre-Regular.eot') format('eot'),
        url('fonts/calibre/hinted-Calibre-Regular.woff') format('woff'),
        url('fonts/calibre/hinted-Calibre-Regular.woff2') format('woff2'),
        url('fonts/calibre/hinted-Calibre-Regular.svg#Calibre-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Averta';
    src: url('fonts/averta-black/hinted-Averta-Black.ttf') format('truetype'),
        url('fonts/averta-black/hinted-Averta-Black.eot') format('eot'),
        url('fonts/averta-black/hinted-Averta-Black.woff') format('woff'),
        url('fonts/averta-black/hinted-Averta-Black.woff2') format('woff2'),
        url('fonts/averta-black/hinted-Averta-Black.svg#Averta-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}
/*Common Css Start*/
* {
	font-family: 'Calibre', sans-serif;
	-webkit-appearance: none;
	-moz-border-radius: 0px;
	-webkit-border-radius: 0px;
	border-radius: 0px;
	outline-width: 0px;
}

html {
	margin: 0px;
	padding: 0px;
	width: 100%;
	height: 100%;
}

a,
input,
button {
	text-decoration: none;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
button:focus {
    outline: none;
}
a:hover,
a:hover h1,
a:hover h2,
a:hover h3,
a:hover h4,
a:hover h5,
a:hover h6,
.blogs .blog-title a:hover h5 {
	text-decoration: none;
    color:#00BBB4;
}

a img,
img {
	border: 0px;
	max-width: 100%;
	text-decoration: none;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

a:hover,
a:focus,
a:visited,
a:active {
	outline: none;
	text-decoration: none;
}

.no-gutter {
	margin: 0 !important;
	padding: 0 !important;
}

p {
	margin: 0px;
	line-height: normal;
}
h1, h2, h3, h4, h5, h6
{
   color: #2e284c;
}
ul {
	margin: 0px;
	padding: 0px;
}

body {
	width: 100%;
	height: auto;
	font-size: 14px;
	font-weight: 400;
}
/*Common Css End*/

/*Home Page Css Start*/
.main-container{
    height: 100%;
}
.main-container .background-content{
    content: " ";
    background-image: url(../images/rioverde.jpg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    background-size: cover;
	background-repeat: no-repeat;
    background-position: center;
}
.page-content-div {
    margin:0px;
    padding:0px;
    width:100%;
    height:100%;
    position: relative;
}
.main-container .form-sec{
    background: #FFFFFF;
    border-radius: 10px;
    margin: 0px;
    padding: 0;
    overflow: hidden;
    max-width: 450px;
    position: absolute;
    left: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.form-sec .logo-img {
    margin-top: 70px;
    text-align: center;
}
.form-sec .form-content {
    margin-top: 45px;
    text-align: center;
}
.form-sec .form-content h4{
    position: relative;
    font-family: 'Calibre';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: #8087A3;
}
.form-sec .form-content p {
    font-family: 'Calibre';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #8087A3;
    padding: 16px 65px;
}
/*Form Area*/
.form-sec .form-area {
    margin: 30px 60px;
    margin-bottom: 150px;
}
.form-area .form-group .form-control {
    background: #FFFFFF;
    border: 2px solid #DDEAFF;
    box-sizing: border-box;
    border-radius: 5px;
}
.form-area .form-group .form-control:focus{
    border: 2px solid #2e284c;
    box-shadow: none;
}

.form-area .form-group input[type=text], .form-area .form-group input[type=password] {
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #8087A3;
    padding: 20px 20px 21px;
    margin-bottom: 20px;
}
.form-area .forgot-link {
    text-align: right;
    margin-bottom: 32px;
}
.form-area .forgot-link a {
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #055EA9;
}
.form-area .forgot-link a:hover {
    color: #05A95A;
}
.form-area .form-group input[type=button] {
    background: #00a367;
    border-radius: 5px;
    font-family: Calibre;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #FFFFFF;
    border: none;
    height: 45px;
    
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
.form-area .form-group input[type=button]:hover {
    background: #FFFFFF;
    color: #2e284c;
    border:1px solid #2e284c;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
/*Person Detail Area */
.form-sec .user-info{
    padding-top: 100px;
    position: relative;
}
.form-sec .user-info:before{
    content: "";
    background-image: url(../images/bg-img.png);
    background-position: bottom left;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    background-size: cover;
}
.form-sec .user-info .person-detail{
    position: relative;
    text-align: start;
    margin-left: 22px;
    padding-bottom: 20px;
}
.form-sec .user-info .person-detail h4{
    font-family: 'TT Commons';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    margin: 0px 0px 3px;
}
.form-sec .user-info .person-detail a{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #FFFFFF;
}
.form-sec .user-info .person-detail a:hover{
    text-decoration: none;
}
.form-sec .user-info .person-detail img{
    border-radius: 100%;
    float: left;
    margin-right: 15px;
}
/* Image Description Area*/
.img-content p, .mobile-img-content p {
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #FFFFFF;
    margin-bottom: 10px;
    text-shadow: 2px 2px 5px #000;
    font-weight: 500;
}
.img-content, .mobile-img-content {
    bottom: 40px;
    position: absolute;
    right: 30px;
}
.mobile-display-section {
    display:none;
    position: relative;
}
/*Home Page Css End*/

/*content-stats*/
.content-stats
{
background: #FFFFFF;
border: 1px solid #DDEAFF;
box-sizing: border-box;
border-radius: 10px;
padding: 1rem;
}
/*content-stats*/

/*content-indicadores*/
.content-indicador
{
background: #FFFFFF;
border: 1px solid #DDEAFF;
box-sizing: border-box;
border-radius: 10px;
padding: 3rem 1rem;
}
.content-indicador h2
{
  font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: rgba(49, 54, 77, 0.6);
    word-break: break-word;
    text-align: center;
    margin-top: 2rem;
}
.content-indicador h5
{
text-align: center;
}
/*content-indicadores*/

/*Widget Page Css Start*/
#my-page .main-container{
    min-height: 100vh;
}
.main-container.bg-img{
    background-image: url(../images/bg-footer.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
}
.main-container .header-cnt{
    padding-top: 10px;
}
.main-container header .logo-img{
    text-align: center;
    margin-top: 5px;
}
.main-container header .user-img{
    float: right;
    margin-top: 13px;
    margin-right: 53px;
}
header .menu-btn{
    margin-top: 10px;
}
header .menu-btn:hover{
    color: #00a531;
}
header .menu-btn img:hover{
    color:#00a531;
}
header .menu {
    margin-top: 32px;
    display: inline-block;
}
.menu nav ul li {
    margin: 0px 25px;
    padding-bottom: 5px;
}
.menu nav ul li:first-child{
    margin-left: 15px;
}
.menu nav ul li a{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color:#2b244b;
}
.menu nav ul li a:hover{
    color:#00a531;
}
.menu nav ul li.active{
    border-bottom: 3px solid #00a531;
}
/*Mmenu Css*/
:root {
    --mb-bar-spacing: 6px;
}
.mburger {
    width: 45px;
    height: 36px;
    --mb-bar-height: 2px;
    padding: 0px;
    border: 1px solid #D6DEED;
    border-radius: 5px;
    margin-top: 13px;
}

.mm-navbar,
.mm-listitem:after{
    border: none;
}

.mm-panels {
    background: #fff;
}

.mburger b{
    width: 20px;
    border: 1px solid #252C64;
}
.mm-navbar .mm-navbar__title{
    justify-content: left;
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 43px;
    color: #2e284c !important;
}
.mm-listview {
    margin-left: 70px;
    margin-top: 15px;
}
.mm-navbar.mm-navbar_sticky{
    padding-top: 40px;
    padding-left: 20px;
}
.mm-listview .mm-listitem{
    margin-bottom: 20px;
}
.mm-listview .mm-listitem .mm-listitem__text{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: rgba(16, 31, 100, 0.75);
    border-bottom: 3px solid transparent;
    padding-left: 0;
    margin-left: 20px;
}
.mm-listview .mm-listitem .mm-listitem__text:focus{
    border-bottom: 3px solid #00a531;
    color: #00a531;
    padding-left: 0;
    margin-left: 20px;
}
.mm-listview .mm-listitem .mm-listitem__text:hover{
    border-bottom: 3px solid #00a531;
    color: #00a531;
    padding-left: 0;
    margin-left: 20px;
}
.mm-panel{
    background-color: #ffff;
}
.mm-navbars_bottom .mm-navbar, .mm-navbar{
    border: none !important;
    background-color: #ffff;
}
/*Mmenu Footer Content*/
.bottom-cnt{
    background: #31364D;
    border-radius: 10px;
    margin-bottom: 25px;
    margin: 0px 25px 25px 40px;
    padding: 15px 10px;
    display: block;
    position: relative;
    text-align: start;
}
.bottom-cnt:hover {
    color: #fff;
    background: #2e284c;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
.bottom-cnt h4{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
}
a:hover h4{
    color: #FFFFFF !important;
}
.bottom-cnt h4:hover{
    color: #FFFFFF;
}
.bottom-cnt p{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #FFFFFF;
}
.bottom-cnt img{
    border-radius: 50%;
    height: auto;
    width: auto;
    margin-right: 10px;
    float: left;
}
/* Widget Section Area */
.widget-sec{
    margin-top: 20px;
    position: relative;
    padding-bottom: 35px;
}
.widget-sec .add-text{
    text-align: right;
}
.widget-sec .page-title h2{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 43px;
    color: #2e284c;
}
.widget-sec .add-text a{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 38px;
    color: rgba(49, 54, 77, 0.6);
    position: relative;
}
.widget-sec .add-text a:hover{
    color: #26BEFF;
}
.add-text a:after{
    content: " ";
    background-image: url(../images/map-icon.png);
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: left center;
    left: -20px;
}
.widget-sec .content-area{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(175, 185, 219, 0.45);
    border-radius: 10px;
    padding: 40px 60px;
}
.content-area .widget-info{
    border-right: 1px solid #DDEAFF;
}
.content-area .widget-info:last-child{
    border: none;
}
.content-area .widget-info .icon-img {
    display: inline-block;
    text-align: right;
    padding-left: 13px;
}
.content-area .widget-info .description {
    display: inline-block;
    padding: 0;
    padding-left: 10px;
    padding-top: 3px;
}
.content-area .widget-info .description h5{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #101F64;
}
.content-area .widget-info .description p{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    color: rgba(49, 54, 77, 0.3);
}
.content-area .widget-info .description h3{
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: rgba(49, 54, 77, 0.6);
    word-break: break-word;
}
.widget-sec .content-area .widget-info p:last-child{
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -1px;
    color: rgba(49, 54, 77, 0.6);
}
.content-area .widget-info:nth-child(1) {
    /*max-width: 165px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(2) {
    /*max-width: 165px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(3) {
    /* width: 225px !important; */
    /*max-width: 165px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(4) {
    /* width: 225px !important; */
    /*max-width: 165px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(5) {
    /* width: 165px !important; */
    /*max-width: 165px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(6) {
    /* width: 225px !important; */
    /*max-width: 165px;*/
    flex: auto;
}
.content-area .widget-info:nth-child(7) {
    /* width: 225px !important; */
   /* max-width: 165px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(8) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(9) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(10) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(11) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(12) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(13) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(14) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(15) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(16) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(17) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}.content-area .widget-info:nth-child(18) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(19) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(20) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(21) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}.content-area .widget-info:nth-child(22) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(23) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}
.content-area .widget-info:nth-child(24) {
    /* width: 225px !important; */
    /*max-width: 300px !important;*/
    flex: auto;
}
/*About Section*/
.about-sec{
    margin-top: 94px;
}
.about-sec-2{
    margin-top: 60px;
}
.about-sec-2 .row{
    margin-top: 20px;
}
.about-sec .title-sec{
    margin-bottom: 25px;
}
.about-sec .title-sec h3{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #2e284c;
    margin: 0;
}
.about-sec .title-sec p{
    font-family: 'Calibre';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: rgba(49, 54, 77, 0.5);
}
.about-sec .detail-sec ul {
    list-style-type: none;
    margin-right: 100px;
}
.about-sec .detail-sec ul li a{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    color: rgba(49, 54, 77, 0.5);
    padding: 25px;
}
.about-sec .detail-sec ul li a::before{
    content:"•";
    font-size: 19pt;
    padding-right: 10px;
}
.about-sec .detail-sec ul li{
    margin-bottom: 6px;
}
.about-sec .detail-sec ul li:hover{
    background: #dcfbda;
    border-radius: 30px;
    transition: all 300ms ease-in-out;
}
.about-sec .detail-sec ul li.active{
    background: #dcfbda;
    border-radius: 30px;
    transition: all 300ms ease-in-out;
}
.about-sec .detail-sec ul li:hover a::before{
    color: #62EB5F;
}
.map_sec:hover img,
.footer_img:hover img{
 box-shadow: 0 0 10px #d7d7d7;
}
.about-sec .detail-sec img{
    height: auto;
    width: auto;
}
.about-sec .site-data{
    margin-left: 20px;
}
.detail-sec .data-table{
    padding-top: 17px;
}

.detail-sec .data-table table tr{
    display: flex;
    margin-bottom: 10px;
}
.detail-sec .data-table table tr td{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(16, 31, 100, 0.75);
    width: 100%;
}
.detail-sec .data-table table tr td:last-child{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: rgba(49, 54, 77, 0.6);
}
/*Widget Page Css End*/
/*Contact Page Css Start*/
/*header area*/
.main-container{
    min-height: 100vh;
}
.main-container .header-cnt{
    padding-top: 10px;
}
.main-container header .logo-img{
    text-align: right;
    margin-top: 5px;
    margin-right: 85px;
}
.main-container header .user-img{
    float: right;
    margin-top: 13px;
    margin-right: 22px;
}
header .menu-btn{
    margin-top: 10px;
    margin-left: 32px;
}
header .menu-btn:hover{
    color: #00a531;
}
header .menu-btn img:hover{
    color:#00a531;
}
header .select-area{
    margin-top: 22px;
    margin-left: 43px;
}
header .select-area select{
    background: #FFFFFF;
    border: 2px solid #DDEAFF;
    border-radius: 5px;
    padding: 16px 21px;
    padding-right: 124px;
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    color: #8087A3;
}
header .select-area select{
    background-image: url(../images/select-icon.png);
    background-repeat: no-repeat;
    background-position: right 15px center;
    cursor: pointer;
}
header .select-area input[type=submit]{
    background: #055EA9;
    border: 1px solid #055EA9;
    border-radius: 5px;
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 17px 24px 16px 22px;
    margin-left: 5px;
}
header .select-area input[type=submit]:hover{
    color: #055EA9;
    background: #FFFFFF;
    border: 1px solid #055EA9;
}
header .select-area .check-option p{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #4B72D6;
    margin-right: 7px
}
header .select-area .check-option{
    float:right;
    margin-right: 63px;
    margin-top: 17px;
    display: inline-block;
}
/*Toogle Switch Css*/
.switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 18px;
}
.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #DBF1FD;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 0px;
    bottom: 1px;
    background-color: #43BFEB;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #DBF1FD;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/*Mmenu Css*/
:root {
    --mb-bar-spacing: 6px;
}
.mburger {
    width: 45px;
    height: 36px;
    --mb-bar-height: 2px;
    padding: 0px;
    border: 1px solid #D6DEED;
    border-radius: 5px;
    margin-top: 13px;
}

.mm-navbar,
.mm-listitem:after{
    border: none;
}

.mm-panels {
    background: #fff;
}

.mburger b{
    width: 20px;
    border: 1px solid #252C64;
}
.mm-navbar .mm-navbar__title{
    justify-content: left;
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 43px;
    color: #2e284c !important;
}
.mm-listview {
    margin-left: 70px;
    margin-top: 15px;
}
.mm-navbar.mm-navbar_sticky{
    padding-top: 40px;
    padding-left: 20px;
}
.mm-listview .mm-listitem{
    margin-bottom: 20px;
}
.mm-listview .mm-listitem .mm-listitem__text{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: rgba(16, 31, 100, 0.75);
    border-bottom: 3px solid transparent;
    padding-left: 0;
    margin-left: 20px;
}
.mm-listview .mm-listitem .mm-listitem__text:focus{
    border-bottom: 3px solid #00a531;
    color: #00a531;
    padding-left: 0;
    margin-left: 20px;
}
.mm-listview .mm-listitem .mm-listitem__text:hover{
    border-bottom: 3px solid #00a531;
    color: #00a531;
    padding-left: 0;
    margin-left: 20px;
}
.mm-panel{
    background-color: #ffff;
}
.mm-navbars_bottom .mm-navbar, .mm-navbar{
    border: none !important;
    background-color: #ffff;
}
/*Mmenu Footer Content*/
.bottom-cnt{
    background: #31364D;
    border-radius: 10px;
    margin-bottom: 25px;
    margin: 0px 25px 25px 40px;
    padding: 15px 10px;
    display: block;
    position: relative;
    text-align: start;
}
.bottom-cnt:hover {
    color: #fff;
    background: #2e284c;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
.bottom-cnt h4{
    font-family: 'TT Commons';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
}
a:hover h4{
    color: #FFFFFF !important;
}
.bottom-cnt h4:hover{
    color: #FFFFFF;
}
.bottom-cnt p{
    font-family: 'TT Commons';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #FFFFFF;
}
.bottom-cnt img{
    border-radius: 50%;
    height: auto;
    width: auto;
    margin-right: 10px;
    float: left;
}

/* Rounded sliders */
.slider.round {
    border-radius: 9px;
}

.slider.round:before {
  border-radius: 50%;
}
/*Contact Section Start*/
.contact-sec{
    position: relative;
    margin-top: 15px;
}
.contact-sec .map-img{
    background-image: url(../images/map-screnn-shot.png);
    width: auto;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
}
.contact-sec .map-img .center-cnt{
    width: 100%;
    height: 100%;
    display: table;
}
.contact-sec .map-img .center-cnt P{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #055EA9;
    padding-top: 63px;
}
.contact-sec .map-img .bottom-cnt{
    position: absolute;
    bottom: 0;
    background: #31364D;
    border-radius: 10px;
    margin-bottom: 25px;
    margin-left: 5px;
    padding: 15px 10px;
    width: 350px;
}
.bottom-cnt h4{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    padding-left: 55px;
}
.bottom-cnt p{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #FFFFFF;
    padding-left: 55px;
}
.map-img .bottom-cnt img{
    position: absolute;
    border-radius: 50%;
    height: auto;
    width: auto;
}

.contact-sec .map-img .bottom-cnt:hover{
    color: #fff;
    background: #2e284c;
}
.contact-sec .map-img .bottom-cnt:hover h4{
    color: #fff;
}
.contact-sec .main-content{
    margin: 30px 30px 50px;
}
.contact-sec .sect-title{
    margin-bottom: 20px;
}
.contact-sec .sect-title h3{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #2e284c;
    margin: 0px;
}
.contact-sec .main-content .detail-table{
    margin: 0px;
}
.contact-sec .main-content .detail-table .row{
    margin: 0px;
}
.main-content .detail-table table{
    width: 100%;
}
.detail-table .sec-heading h4{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #112760;
}
.detail-table .sec-heading{
    padding: 15px 0px 15px 40px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.75);
}
.detail-table .sec-content{
    padding: 15px 0px 15px 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.detail-table .sec-content a{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #2e284c;
    position: relative;
    cursor: pointer;
}
.detail-table .sec-content p{
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #31364D;
}
.detail-table .sec-content div:last-child p{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: rgba(49, 54, 77, 0.5);
}
.detail-table .sec-content h4{
    font-family: 'Calibre', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: rgba(49, 54, 77, 0.5);
}

.sec-content .cnnt-1,
.sec-heading .cnnt-1{
    width: 25%;
}
.sec-content .cnnt-2,
.sec-heading .cnnt-2{
    width: 15%;
}
.sec-content .cnnt-3,
.sec-heading .cnnt-3{
    width: 30%;
}
.sec-content .cnnt-4,
.sec-heading .cnnt-4{
    width: 30%;
}
.sec-content .cnnt-1 a::before{
    content: "•";
    font-size: 25pt;
    position: absolute;
    margin-left: -20px;
    top: 0px;
    color: #62EB5F;
}
/*Contact Page Css End*/

/*Responsive Css Start */
@media (max-width: 1440px) {
    /*Contact Page Css*/
    .contact-sec .main-content {
        margin: 30px 10px 50px;
    }
    .sec-content .cnnt-1, .sec-heading .cnnt-1 {
        width: 22%;
    }
    .sec-content .cnnt-3, .sec-heading .cnnt-3 {
        width: 33%;
    }
}
@media (max-width: 1242px){
    /*Widget Page CSS*/
    .widget-sec .content-area {
        padding: 60px 35px;
    }
}
@media (min-width: 1200px){
    /*Widget Page CSS*/
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1252px;
    }
}
@media (max-width: 1199px) and (min-width: 992px){
    /*Contact Page Css*/
    header .select-area select {
        padding: 16px 10px;
        padding-right: 40px;
        font-size: 13px;
        line-height: 16px;
    }
    .contact-sec .main-content {
        margin: 0px;
    }
    .sec-content .cnnt-3, .sec-heading .cnnt-3 {
        width: 25%;
    }
    .sec-content .cnnt-4, .sec-heading .cnnt-4 {
        width: 35%;
    }
}
@media (max-width: 1199px) {
    /*Widget Page CSS*/
    .widget-sec .content-area {
        padding: 40px;
    }
    .about-sec .detail-sec ul {
        margin-right: 0px;
    }
    .content-area .widget-info:nth-child(1) {
        max-width: unset !important;
        flex: none;
        margin-bottom: 15px;
    }
    .content-area .widget-info:nth-child(2) {
        max-width: unset !important;
        flex: none;
        margin-bottom: 15px;
    }
    .content-area .widget-info:nth-child(3) {
        max-width: unset !important;
        flex: none;
        margin-bottom: 15px;
        border-right: none;
    }
    .content-area .widget-info:nth-child(4) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(5) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(6) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(7) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(8) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(9) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(10) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(11) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(12) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(13) {
        max-width: unset !important;
        flex: none;
        margin-bottom: 15px;
        border-right: none;
    }
    .content-area .widget-info:nth-child(14) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(15) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(16) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(17) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(18) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(19) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(20) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info:nth-child(21) {
        max-width: unset !important;
        flex: none;
        margin-bottom: 15px;
    }
    .content-area .widget-info:nth-child(22) {
        max-width: unset !important;
        flex: none;
        margin-bottom: 15px;
    }
    .content-area .widget-info:nth-child(23) {
        max-width: unset !important;
        flex: none;
        margin-bottom: 15px;
        border-right: none;
    }
    .content-area .widget-info:nth-child(24) {
        max-width: unset !important;
        flex: none;
    }
    .content-area .widget-info{
        width: 33% !important;
    }
}

@media (max-width: 991px){
    /*Home Page Css Start*/
    .main-container .form-sec {
        max-width: 385px;
        left: 0px;
    }
    .img-content {
        right: 15px;
    }
    .form-sec .form-content p {
        padding: 16px 40px;
    }
    .form-sec .form-area {
        margin: 30px 40px;
        margin-bottom: 70px;
    }
    /*Home Page Css End*/

    /*Widget Page Css Start*/
    .about-sec .site-data {
        margin-left: 0px;
        margin-top: 40px;
    }
    .content-area .widget-info {
        border-right: none;
    }
    .content-area .widget-info .description {
        margin-bottom: 30px;
    }
     .about-sec {
        padding-top: 30px;
        border-top: 1px solid #DDEAFF;
        margin-top: 0px;
    }
    .content-area .widget-info:nth-child(1) {
        margin-bottom: 0px;
    }
    .content-area .widget-info:nth-child(2) {
        margin-bottom: 0px;
    }
    .content-area .widget-info:nth-child(3) {
        margin-bottom: 0px;
    }
    /*Widget Page Css End*/

    /*Contact Page Css Start*/
    /*Header Section*/
     header .select-area select {
        width: 310px;
    }
    header .menu-btn {
        margin-top: 10px;
        margin-left: 0px;
    }
    .main-container header .logo-img {
        text-align: center;
        margin-top: 5px;
        margin-right: 0px;
    }
    .main-container header .user-img {
        float: right;
        margin-top: 13px;
        margin-right: 0px;
    }
    header .select-area .check-option {
        float: none;
        margin-right: 0px;
        display: flex;
        justify-content: center;
    }
    header .select-area form{
        display: none;
    }
    .contact-sec .map-img{
        display: none;
    }
    .main-content .sect-title{
        display: none;
    }
    .detail-table{
        max-width: 850px;
    }
    .main-container {
        background: url('../images/bg-img.png') no-repeat bottom center;
        background-size: 100%;
    }
    .contact-sec {
        background-color: #FFFFFf;
        width: 90%;
        margin-top: 0;
    }
    /*Contact Page Css End*/
}

@media (min-height: 860px) and (max-height: 925px) {
    /*Home Page Css*/
    .form-sec .form-area {
        margin-bottom: 50px;
    }
}
@media (max-height: 859px) {
    /*Home Page Css Start*/
    .form-sec .logo-img {
        margin-top: 35px;
    }
    .form-sec .form-content {
        margin-top: 28px;
    }
    .form-sec .form-area {
        margin: 20px 60px 50px;
    }
    .form-area .forgot-link {
        text-align: right;
        margin-bottom: 25px;
    }
    .form-area .form-group input[type=text], .form-area .form-group input[type=password] {
        margin-bottom: 0px;
    }
    .logo-img img {
        width: 150px;
    }
     .content-indicador
    {
        margin-top: 1rem;
    }
    /*Home Page Css End*/
}

@media (max-width: 767px){
    /*Home Page Css Start*/
   .main-container .background-content{
        background:none;
        padding: 0px;
        position: static;
        height: auto;
    }
    .mobile-display-section {
        display: block;
        width: 100%;
    }
    .mobile-display-section img {
        width: 100%;
    }
    .form-sec .form-content p {
        display: none;
    }
    .main-container .form-sec {
        max-width: none;
        width: 100%;
        left: 0px;
        position: static;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        border-radius: 0px;
        margin: 0px !important;
    }
    .img-content {
        display: none;
    }
    .form-sec .user-info {
        padding-top: 210px;
    }
    /*Home Page Css End*/

    /*Widget Page Css Start*/
    /*Header Section */
    header .menu {
        display: none;
    }
    .main-container .header-cnt {
        padding-top: 25px;
    }
    /*Widget Section */
    .widget-sec .page-title h2 {
        margin: 0;
    }
    .content-area .widget-info .description h3 {
        font-size: 18px;
        line-height: 20px;
    }
    .widget-sec .add-text {
        text-align: start;
    }
    .widget-sec .add-text a{
        padding-left: 18px;
    }
    .widget-sec .content-area {
        padding: 40px;
    }
    .about-sec {
        padding-top: 10px;
        border-top: none;
        margin-top: 0px;
    }
    .title-sec:nth-child(1) {
        padding-top: 30px;
        border-top: 1px solid #DDEAFF;
    }
    .about-sec .detail-sec ul {
        margin-bottom: 20px;
    }
    .add-text a:after {
        left: 0px;
    }
    .main-container header .user-img {
        margin-right: 0px;
    }
    .content-area .widget-info {
        width: 50% !important;
    }
    /*Widget PAge Css End*/
    /*Contact Page Css Start*/
    .contact-sec .main-content .detail-table {
        min-width: 600px;
    }
    .contact-sec .main-content {
        overflow: auto;
    }
     .content-indicador
    {
        margin-top: 1rem;
    }
    /*Contact Page Css End*/
}
@media (max-height: 749px) {
    /*Home Page Css Start*/
    .main-container .background-content {
        height: auto;
    }
    .page-content-div {
        height: auto;
    }
    .main-container .form-sec {
        position: static;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        margin: 40px 30px;
    }
     .content-indicador
    {
        margin-top: 1rem;
    }
    /*Home Page Css End*/
}
@media (max-width: 575px){
    /*Home Page Css Start*/
    .form-sec .user-info {
        padding-top: 132px;
    }
    .img-content, .mobile-img-content {
        bottom: 30px;
        right: 15px;
    }
    .form-sec .form-area {
        margin: 30px 40px 50px !important;
    }
    /*Home Page Css End*/

    /*Widget Page Css Start*/
    /*Header Section */
    .main-container header .logo-img {
        margin-top: 10px;
    }
    /*Widget Section */
    .widget-sec .content-area {
        padding: 40px 20px;
    }
    .about-sec .detail-sec ul li a{
        font-size: 15px;
        line-height: 36px;
    }
    .content-area .widget-info {
        width: 300px;
    }
    .content-area .widget-info {
        width: 100% !important;
    }
    /*Mmenu css*/
    .mm-navbars_bottom {
        display: none;
    }
    .mm-menu_offcanvas {
        width: 40%;
    }
    /*Widget PAge Css End*/
    /*Contact Page Css Start*/
    /*Header Section */
    .main-container header .logo-img {
        margin-top: 10px;
    }
    .main-container header .user-img {
        margin-right: 0px;
    }
    /*Contact Section*/
    .contact-sec .sect-title h3 {
        font-size: 22px;
        line-height: 24px;
    }
    .detail-table .sec-heading h4 {
        font-size: 17px;
        line-height: 18px;
    }
    .detail-table .sec-content h3 {
        font-size: 16px;
        line-height: 20px;
    }
    .detail-table .sec-content h4 {
        font-size: 16px;
        line-height: 20px;
    }
    .detail-table .sec-content div:last-child p {
        font-size: 16px;
        line-height: 19px;
    }
    /*Mmenu css*/
    .mm-navbars_bottom {
        display: none;
    }
    .mm-menu_offcanvas {
        width: 40%;
    }
    .content-indicador
    {
        margin-top: 1rem;
    }
    /*Contact Page Css End*/
}
@media (max-width: 479px){
    /*Home Page Css*/
    .form-sec .user-info {
        padding-top: 100px;
    }
    .content-indicador
    {
        margin-top: 1rem;
    }
}
/*Responsive Css End */
/* Localized */